.menu {
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
    gap: 6px;
  }

  &Item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s linear;
    filter: invert(47%) sepia(0%) saturate(0%) hue-rotate(352deg)
      brightness(92%) contrast(92%);

    &Active {
      filter: invert(40%) sepia(31%) saturate(1615%) hue-rotate(216deg)
        brightness(89%) contrast(93%);
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #171717;
        bottom: 16px;
        left: 0;
      }
    }
  }
}
