.customContainer {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 789px;
  max-width: 90vw;
  min-height: 560px;
  padding: 19px 24px;
  border-radius: 0.25rem;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);

  &Small {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 789px;
    max-width: 90vw;
    min-height: 120px;
    padding: 19px 24px;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  }

  &Medium {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    width: 789px;
    min-height: 386px;
    max-width: 90vw;
    padding: 19px 24px;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  }
}
